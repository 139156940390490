/** @jsx jsx */
import React from "react";
import { jsx, css } from "@emotion/core";
interface Props extends Omit<React.SVGProps<SVGSVGElement>, "css"> {
  weight?: "thin" | "normal";
}
export const IconArrowLeft = React.forwardRef<SVGSVGElement, Props>(
  ({ weight = "normal", ...props }, ref) => (
    <svg
      viewBox="0 0 14 24"
      ref={ref}
      css={css`
        * {
          vector-effect: non-scaling-stroke;
        }
        overflow: visible;
        height: 24px;
      `}
      {...props}
    >
      <path
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight === "normal" ? 1.5 : 1}
        d="M12.5.75L1.78 11.47a.749.749 0 0 0-.001 1.059l.001.001L12.5 23.25"
      />
    </svg>
  )
);
