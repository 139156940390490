// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-theme-guide-src-components-template-js": () => import("./../../../node_modules/gatsby-theme-guide/src/components/template.js" /* webpackChunkName: "component---node-modules-gatsby-theme-guide-src-components-template-js" */),
  "component---src-gatsby-theme-guide-core-pages-404-js": () => import("./../../../src/gatsby-theme-guide-core/pages/404.js" /* webpackChunkName: "component---src-gatsby-theme-guide-core-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-me-js": () => import("./../../../src/pages/me.js" /* webpackChunkName: "component---src-pages-me-js" */),
  "component---src-pages-paypal-[package]-js": () => import("./../../../src/pages/paypal/[package].js" /* webpackChunkName: "component---src-pages-paypal-[package]-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-team-[token]-js": () => import("./../../../src/pages/team/[token].js" /* webpackChunkName: "component---src-pages-team-[token]-js" */),
  "component---src-pages-tshirt-js": () => import("./../../../src/pages/tshirt.js" /* webpackChunkName: "component---src-pages-tshirt-js" */),
  "component---src-pages-unsubscribe-[token]-js": () => import("./../../../src/pages/unsubscribe/[token].js" /* webpackChunkName: "component---src-pages-unsubscribe-[token]-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-pages-welcome-js": () => import("./../../../src/pages/welcome.js" /* webpackChunkName: "component---src-pages-welcome-js" */)
}

