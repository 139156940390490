/** @jsx jsx */
import React from "react";
import { jsx, css } from "@emotion/core";
interface Props extends Omit<React.SVGProps<SVGSVGElement>, "css"> {
  weight?: "thin" | "normal";
}
export const IconOutlink = React.forwardRef<SVGSVGElement, Props>(
  ({ weight = "normal", ...props }, ref) => (
    <svg
      viewBox="0 0 25 25"
      ref={ref}
      css={css`
        * {
          vector-effect: non-scaling-stroke;
        }
        overflow: visible;
        height: 25px;
      `}
      {...props}
    >
      <path
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight === "normal" ? 1.5 : 1}
        d="M12.141 4.894H3.576A1.977 1.977 0 0 0 1.6 6.871v14.494c0 1.09.885 1.976 1.976 1.976h14.495a1.977 1.977 0 0 0 1.976-1.976V12.8M17.412 1.6h4.94a.99.99 0 0 1 .99.988V7.53m-10.883 4.952L23.05 1.89"
      />
    </svg>
  )
);
