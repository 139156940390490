/** @jsx jsx */
import React from "react";
import { jsx, css } from "@emotion/core";
interface Props extends Omit<React.SVGProps<SVGSVGElement>, "css"> {
  weight?: "thin" | "normal";
}
export const IconCollapseList = React.forwardRef<SVGSVGElement, Props>(
  ({ weight = "normal", ...props }, ref) => (
    <svg
      viewBox="0 0 24 24"
      ref={ref}
      css={css`
        * {
          vector-effect: non-scaling-stroke;
        }
        overflow: visible;
        height: 24px;
      `}
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight === "normal" ? 1.5 : 1}
      >
        <path d="M3.75.748l7.72 7.72a.749.749 0 0 0 1.059.001l.001-.001 7.72-7.72M3.75 23.248l7.72-7.719a.749.749 0 0 1 1.059-.001l.001.001 7.72 7.719" />
      </g>
    </svg>
  )
);
