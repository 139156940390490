/** @jsx jsx */
import React from "react";
import { jsx, css } from "@emotion/core";
interface Props extends Omit<React.SVGProps<SVGSVGElement>, "css"> {
  weight?: "thin" | "normal";
}
export const IconMenu = React.forwardRef<SVGSVGElement, Props>(
  ({ weight = "normal", ...props }, ref) => (
    <svg
      viewBox="0 0 24 24"
      ref={ref}
      css={css`
        * {
          vector-effect: non-scaling-stroke;
        }
        overflow: visible;
        height: 24px;
      `}
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight === "normal" ? 1.5 : 1}
      >
        <path d="M2.25 18.003h19.5M2.25 12.003h19.5M2.25 6.003h19.5" />
      </g>
    </svg>
  )
);
